import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor() {}

  /**
   * returns a filtered list by its list item's
   * name field. This function has no effect to the list
   * and will return the same list if:
   * 1) filter param is falsy (false, 0, '', null, undefined, NaN)
   * 2) list param length = 0
   * 3) list's item (object) inside has no 'name' field
   */
  filterListByName(filter: string, list: Array<any>) {
    if (!filter || list.length === 0 || !('name' in list[0])) return list;

    filter = filter.toLowerCase();
    return list.filter((item) => item.name?.toLowerCase().indexOf(filter) >= 0);
  }
}
